import React, { Component } from 'react';
import styled from 'styled-components';

import { Button } from './Theme';

const InputWrapper = styled.div`
  margin-bottom: 10px;
  input, select, textarea {
    width:100%;
    padding: 10px;
  }
`;

class ContactForm extends Component {

  render() {

    return (
      <form name="contact" action="/success" data-netlify="true" method="post" netlify-honeypot="department">
        <InputWrapper>
          <input type="text" name="name" placeholder="Your Name*" required></input>
        </InputWrapper>
        <InputWrapper>
          <input type="email" name="email" placeholder="Your Email*" required></input>
        </InputWrapper>
        <InputWrapper>
          <input type="text" name="company" placeholder="Your Company*" required></input>
        </InputWrapper>
        <InputWrapper>
          <input type="text" name="position" placeholder="Your Position*" required></input>
        </InputWrapper>
        <InputWrapper>
          <input type="text" name="phone" placeholder="Your Phone Number*" required></input>
        </InputWrapper>
        <InputWrapper className={`hidden`}>
          <label>Don't fill this out if you are a human
          <input type="text" name="department" placeholder="Your Department*" ></input></label>
        </InputWrapper>
        <InputWrapper>
          <textarea name="message" placeholder="Your Message"></textarea>
        </InputWrapper>

        <input type="hidden" name="page" value={this.props.page} />
        <input type="hidden" name="form-name" value="contact" />
        <Button>Send</Button>
      </form>
    )
  }
}

export default ContactForm;