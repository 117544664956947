import React from 'react'
import styled from 'styled-components'
import { Container } from '../components/Theme';

const BannerWrapper = styled.div`
background-image: url(${props => props.image});
background-size: cover;
background-position: 0% 30%;
padding: 100px 0;
`;

const ContentContainer = styled.div`
background-color:rgba(0, 94, 107, 0.75);
color: white;
width: 100%;
border-radius: 500px;
padding: 30px 75px;
@media(min-width: 800px){
  width: 60%;
}
`;

const Title = styled.h1`
text-transform: uppercase;
font-size: 1.25rem;
line-height: 1.25rem;
font-weight: bold !important;
margin: 15px 0;
@media(min-width: 800px){
  font-size: 1.75rem;
  line-height: 1.75rem;
}
`;


const TitleBanner = (props) => {
  return (
    <BannerWrapper image={props.image}>
      <Container>
        <ContentContainer>
          <Title>{props.title}</Title>
        </ContentContainer>
      </Container>
    </BannerWrapper>
  )
}

export default TitleBanner
