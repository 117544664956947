import React, {Component} from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { Remarkable } from 'remarkable'

import Layout from '../components/Layout'
import TitleBanner from '../components/TitleBanner'
import { Container } from '../components/Theme'
import Contact from '../components/Contact' 

import defaultImage from '../../static/assets/phone.jpg'

const md = new Remarkable();

const ColumnContainer = styled(Container)`
display: grid;
grid-template-columns: 1fr;
grid-column-gap: 4rem;
h2 {
  min-height: 64px;
}
`

const TwoColumnContainer = styled(ColumnContainer)`
@media(min-width: 700px){
  grid-template-columns: 1fr 1fr;
}
`

const ThreeColumnContainer = styled(ColumnContainer)`
@media(min-width: 700px){
  grid-template-columns: 1fr 1fr 1fr;
}
`

const ColumnIntroText = styled.div`
min-height: 156px;
margin-bottom: 1rem; 
`

const ButtonWrapper = styled.div`
width: 100%;
text-align: center;
padding-top:1rem;
a {
  width: 100%;
  max-width: 300px;
}
`

const MiddleBanner = styled.div`
background-image: url(${props => props.image});
background-size: cover;
background-position: center;
padding: 50px 0;
min-height: 400px;
`

const ContactWrapper = styled.div`
background-color: rgba(0,94,107,1);
color: #fff;
form, button {
  margin-bottom:0;
}
`



export default class SubPageLayout extends Component {

  render(){

    // Pull data from graphql
    const {markdownRemark: page} = this.props.data;

    // Set banner background image
    const bannerImage = (page.frontmatter.image) ? page.frontmatter.image.childImageSharp.fluid.src : defaultImage

    // Set required column container
    let ActiveContainer = ColumnContainer;

    if(page.frontmatter.columns){
      
      switch (page.frontmatter.columns.length) {
        case 1:
        case 5:
        case 7:
          
          ActiveContainer = ColumnContainer;
          
          break;
  
        case 2:
        case 4:
        case 8:
              
          ActiveContainer = TwoColumnContainer;
  
          break;
      
        default:
  
            ActiveContainer = ThreeColumnContainer;
  
          break;
      }
      
    }

    return(
      <Layout>
        <Helmet
          title={`${page.frontmatter.title} - Metro Communications News`}
          meta={[
            { name: 'description', content: page.excerpt },
            { name: 'keywords', content: `` },
          ]}
        />
        <TitleBanner title={page.frontmatter.title} image={bannerImage} />
        <Container dangerouslySetInnerHTML={{__html: page.html}} />
        {page.frontmatter.middlebanner && <MiddleBanner image={page.frontmatter.middlebanner.childImageSharp.fluid.src}/>}
        <ActiveContainer>
          {page.frontmatter.columns && page.frontmatter.columns.map(column => (
            <div>
              <h2>{column.name}</h2>
              <ColumnIntroText dangerouslySetInnerHTML={{__html: md.render(column.content)}}></ColumnIntroText>
              <div>
                <Img fluid={column.image.childImageSharp.fluid} alt={column.name} />
              </div>

            </div>
          ))}
        </ActiveContainer>
        <ContactWrapper>
          <Contact page={page.frontmatter.title}/>
        </ContactWrapper>

      </Layout>
    )
  }
}

export const subPageTemplateQuery = graphql`
  query subPageTemplateQuery($id: String!) {
    markdownRemark(id: {eq: $id}){
      id
      excerpt
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
        middlebanner {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
        columns {
          content
          name
          image {
            childImageSharp {
              fluid(maxWidth:515) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`