import React from "react"
import styled from "styled-components"

import { Container } from "./Theme"
import ContactForm from "./ContactForm"

const ContactContainer = styled(Container)`
  display: grid;
  padding-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Contact = props => {
  return (
    <ContactContainer>
      <div>
        <h2>Contact Us</h2>
        <ContactForm page={props.page} />
      </div>
      <div>
        <h3>Address</h3>
        <p>
          Metro Communications Ltd
          <br />
          128 City Road
          <br />
          London
          <br />
          EC1V 2NX
          <br />
        </p>
        <h3>Email</h3>
        <p>hello@metrocomms.co.uk</p>
        <h3>Phone</h3>
        <p>0203 906 1400</p>
      </div>
    </ContactContainer>
  )
}

export default Contact
